export const formatGardeners = (gardeners) => {
  return gardeners.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
    .map((gardener) => {
      return {
        id: gardener.id,
        name: gardener.name,
        email: gardener.email.toLowerCase(),
        slack_id: gardener.slack_id,
        available: gardener.available,
        customers: gardener.number_of_customers,
        customers_threshold: gardener.customers_threshold,
        orders_today: gardener.total_orders_today,
        orders_today_completed: gardener.completed_orders_today,
        location_areas: gardener.location_areas,
      };
    });
};

export const formatGardenerCustomers = (customers) => {
  return customers.map((customer) => {
    return {
      id: customer.id,
      name: customer.name.toLowerCase(),
      email: customer.email.toLowerCase(),
      gardener: customer.gardener,
      active_status: customer.status,
      status: customer.customer_status,
      phone_number: customer.phone_number,
      services:
        customer.next_billing_date.length && customer.next_billing_date[0].plan
          ? Object.keys(customer.next_billing_date[0].plan).sort((a, b) =>
              a < b ? -1 : 1,
            )
          : [],
      subscription_amount: customer.next_billing_date.length
        ? customer.next_billing_date[0].amount
        : 0,
      subscription_due: customer.next_billing_date.length
        ? customer.next_billing_date[0].next_charge_date
        : null,
    };
  });
};

const parseOrder = (order, customer, service) => {
  let parsedOrder = {
    id: order.customer_order_id,
    customer: customer.toLowerCase(),
    confirmed: !!order.is_confirmed,
    delivery: order.service_delivery_date,
  };

  switch (service) {
    case "beauty":
      parsedOrder.type = order.beauty_type || null;
      parsedOrder.variations = order.service_partners.map((item) => {
        return {
          variation: item.specifics,
          addons: item.beauty_addons_for_specifics,
          sp: item.service_provider,
        };
      });
      break;
    case "cleaning":
      parsedOrder.type = order.service_partners[0].specifics || null;
      break;
    case "laundry":
      parsedOrder.type = order.service_partners[0].specifics || null;
      parsedOrder.bags = order.no_of_bags || 0;
      parsedOrder.pickup = order.service_pickup_date || null;
      break;
    case "meal":
      parsedOrder.meals = order.service_partners.map((item) => {
        return {
          item: item.specifics,
          sp: item.service_provider,
        };
      });
      break;
    default:
      break;
  }
  return parsedOrder;
};

export const formatGardenerOrders = (customers, service) => {
  let orders = [];
  customers.forEach((customer) => {
    let customerName = Object.keys(customer)[0];
    customer[customerName].map((order) => {
      let parsedOrder = parseOrder(order, customerName, service);
      orders.push(parsedOrder);
    });
  });
  return orders;
};
