<template>
  <div class="overview-card">
    <div class="overview-card--heading">
      <p>{{ totalCustomersToPay }} Customers Meant To Pay This Week 🚨</p>
      <el-button type="primary" @click="openTracker">
        Open Payment Tracker
        <i class="el-icon-arrow-right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import payment from "@/requests/gardeners/payment-tracker";

export default {
  name: "PaymentTrackerOverview",
  props: {
    view: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      summary: {},
    };
  },
  computed: {
    totalCustomersToPay() {
      return this.summary.total_customers_expected_to_pay;
    },
  },
  created() {
    this.getSummary();
  },
  methods: {
    getSummary() {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period: "thisweek",
      });

      let payload = {
        customer_type: "individual",
        start_date,
        end_date,
      };

      if (this.view === "gardener") {
        payload.gardener_id = this.$route.params.id;
      }
      payment
        .summary(payload)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.summary = data;
          }
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    openTracker() {
      if (this.view === "admin") {
        this.$router.push({ name: `gardeners.payment-tracker` });
      } else {
        this.$router.push({
          name: `gardeners.payment-tracker`,
          params: { id: this.$route.params.id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-card {
  background-color: #fffdf5;
  margin-bottom: 50px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #ffebb2;
  border-radius: 8px;

  &--heading {
    grid-area: 1 / 1 / 2 / 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    p {
      color: var(--eden-grey-primary);
      font-size: 1rem;
      font-weight: 500;
    }
  }
}
</style>
