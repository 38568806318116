<template>
  <div>
    <eden-page-stats
      :loading="loading"
      :stats="gardenerSales"
      :show-periods="true"
      @set-period="getGardenerSales"
    >
      <template slot="actions">
        <el-button @click="showBreakdown" type="text"> See breakdown</el-button>
      </template>
    </eden-page-stats>
    <el-dialog
      :visible.sync="breakdown.show"
      :title="'Breakdown'"
      class="strict"
      append-to-body
      width="50%"
      @close="closeEvent"
    >
      <div class="breakdown-actions">
        <div class="is-flex align-center justify-between flex-wrap">
          <div class="selections">
            <el-dropdown @command="setBreakdownType">
              <el-button type="plain">
                <span>
                  {{ types[breakdown.type] }} <i class="eden-icon-arrow-down" />
                </span>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(value, key, i) in types"
                  :key="i"
                  :command="key"
                  :class="{ selected: key === breakdown.type }"
                  >{{ value }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              v-if="sales.period !== 'custom'"
              @command="setBreakdownPeriod"
            >
              <el-button type="plain">
                <span>
                  {{ periods[breakdown.period] }}
                  <i class="eden-icon-arrow-down" />
                </span>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(value, key, i) in periods"
                  :key="i"
                  :command="key"
                  :class="{ selected: key === breakdown.period }"
                  >{{ value }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div v-if="breakdown.loading" v-loading="breakdown.loading"></div>
      <el-table v-else :data="breakdown.data" height="300">
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Customer </span>
            </div>
          </template>
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.user"
              :to="{
                name: 'customers.individual',
                params: { id: scope.row.user.id },
              }"
            >
              <span class="font-sm text-primary">
                {{ formatName(scope.row.user.name) }}</span
              ></router-link
            >
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <div class="table--header">
              <span>Type </span>
            </div>
          </template>
          <template slot-scope="scope">
            <el-tag :type="setType(scope.row.conversion_type)">
              {{ formatText(scope.row.conversion_type) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="eden-table-header">
              Previous Services
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <eden-services-list
              v-if="scope.row.old_serivces"
              :services="scope.row.old_services"
            />
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="eden-table-header">
              New Services
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <eden-services-list
              v-if="scope.row.new_serivces"
              :services="scope.row.new_serivces"
            />
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <div class="table--header">
              <span>Date </span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatDate(scope.row.created_at, "d m, y") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import gardener from "@/requests/gardeners/gardener";

export default {
  name: "GardenerSales",
  props: {
    gardenerId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      sales: {
        period: "today",
        from: null,
        to: null,
        data: {},
      },
      breakdown: {
        type: "all",
        period: "today",
        show: false,
        loading: false,
        data: [],
      },
      types: {
        all: "All Types",
        resurrection: "Resurrection",
        cross_sell: "Crosssell",
        downsell: "Downsell",
        upsell: "Upsell",
      },
      periods: {
        today: "Today",
        thisweek: "This week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    gardenerSales() {
      const sales = this.sales.data;
      return [
        {
          label: "Upsells",
          figure: sales.total_upsell,
        },
        {
          label: "Cross sells",
          figure: sales["total_cross-sell"],
        },

        {
          label: "Resurrections",
          figure: sales.total_resurrection,
        },
        {
          label: "Downsells",
          figure: sales.total_downsell,
        },
      ];
    },
  },
  created() {
    this.getGardenerSales({ period: this.sales.period });
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    setBreakdownPeriod(period) {
      this.breakdown.period = period;
      this.getGardenerSalesBreakdown();
    },
    setBreakdownType(type) {
      this.breakdown.type = type;
      this.getGardenerSalesBreakdown();
    },
    getGardenerSales({ period, to, from }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      gardener
        .sales({ id: this.gardenerId, start_date, end_date })
        .then((response) => {
          const { status, data } = response.data;

          if (status) {
            this.sales.data = data;
            this.sales.from = start_date;
            this.sales.to = end_date;
            this.sales.period = period;

            if (period !== "custom") {
              this.breakdown.period = period;
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    showBreakdown() {
      this.breakdown.show = true;
      this.getGardenerSalesBreakdown();
    },
    getGardenerSalesBreakdown() {
      this.breakdown.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period: this.breakdown.period,
        from: this.sales.from,
        to: this.sales.to,
      });

      let payload = {
        id: this.gardenerId,
        start_date:
          this.sales.period === "custom" ? this.sales.from : start_date,
        end_date: this.sales.period === "custom" ? this.sales.to : end_date,
      };

      if (this.breakdown.type !== "all") {
        payload.type = this.breakdown.type;
      }
      gardener
        .salesBreakdown(payload)
        .then((response) => {
          const { status, data } = response.data;

          if (status) {
            this.breakdown.data = data;
          }
          this.breakdown.loading = false;
        })
        .catch((error) => {
          this.breakdown.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.breakdown-actions {
  margin-bottom: 24px;

  .selections {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: auto;

    .el-dropdown {
      margin-right: 5px;
    }
  }
}
</style>
