<template>
  <div>
    <eden-table-actions :title="'10 Feedback'" @search="search">
      <template slot="actions">
        <div class="is-flex align-center justify-end">
          <el-dropdown @command="setFeedbackService">
            <el-button type="plain">
              <span>
                {{ services[feedbackService] }}
                <i class="eden-icon-arrow-down" />
              </span>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(value, key, i) in services"
                :key="i"
                :command="key"
                :class="{ selected: key === feedbackService }"
                >{{ value }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="setPeriod">
            <el-button type="plain">
              <span>
                {{ periods[period] }} <i class="eden-icon-arrow-down" />
              </span>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(value, key, i) in periods"
                :key="i"
                :command="key"
                :class="{ selected: key === period }"
                :disabled="key === 'lastweek' || key === 'nexttwodays'"
                >{{ value }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </eden-table-actions>
    <el-table :data="feedback_records" style="width: 100%">
      <el-table-column width="100">
        <template slot="header">
          <div class="table--header">
            <span>Order ID</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm text-primary"> {{ scope.row.order_id }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="100">
        <template slot="header">
          <div class="table--header">
            <span>Customer</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm"> {{ scope.row.customer }} </span>
        </template>
      </el-table-column>
      <el-table-column min-width="100">
        <template slot="header">
          <div class="table--header">
            <span>Service</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tag :type="setServiceType(scope.row.service)">{{
            formatText(scope.row.service)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column min-width="120">
        <template slot="header">
          <div class="table--header">
            <span>Type</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ formatText(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="100">
        <template slot="header">
          <div class="table--header">
            <span>Date</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.date }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="120">
        <template slot="header"> </template>
        <template>
          <router-link to="">
            <span class="font-sm text-primary">View Feedback</span>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "GardenerCustomerFeedback",

  data() {
    return {
      search: true,
      feedbackService: "all",
      services: {
        all: "All Services",
        meal: "Meal",
        laundry: "Laundry",
        cleaning: "Cleaning",
      },
      period: "today",
      periods: {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        tomorrow: "Tomorrow",
        nexttwodays: "Next two days",
        thisweek: "This week",
        nextweek: "Next week",
      },
      feedback_records: [
        {
          order_id: "4506320",
          customer: "Damola Adegoke",
          service: "meal",
          type: "4 meals",
          date: "July 8, 2021",
        },
        {
          order_id: "4506320",
          customer: "Aderinsola Oluwafemi",
          service: "laundry",
          type: "wash & fold",
          date: "July 8, 2021",
        },
        {
          order_id: "4506320",
          customer: "Aderinsola Oluwafemi",
          service: "cleaning",
          type: "light cleaning",
          date: "July 8, 2021",
        },
      ],
      page: 1,
      showPagination: true,
    };
  },
  methods: {
    setPeriod(period) {
      this.period = period;
    },
    setFeedbackService(service) {
      this.feedbackService = service;
    },
  },
};
</script>
