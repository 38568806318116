<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="title">
        <eden-periods :loading="loading" @set-period="setPeriod" />
      </template>
      <template slot="actions">
        <eden-services class="mr-10" @set-service="setService" />
        <el-dropdown @command="setView">
          <el-button type="plain">
            <span> {{ views[view] }} <i class="eden-icon-arrow-down" /> </span>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="orders"
              :class="{ selected: view === 'orders' }"
              >Orders View</el-dropdown-item
            >
            <el-dropdown-item
              command="customers"
              :class="{ selected: view === 'customers' }"
              >Customers View</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="Object.keys(provisionedOrders).length">
        <component
          :is="`${view}-view`"
          :orders="provisionedOrders"
          :service="service"
        />
      </template>
      <eden-content-empty v-else :text="'No orders'" />
    </template>
  </div>
</template>

<script>
import gardener from "@/requests/gardeners/gardener";
import { formatGardenerOrders } from "@/components/Gardeners/Gardener/gardener.config";

export default {
  name: "GardenerProvisionedOrders",
  components: {
    OrdersView: () => import("./OrdersView"),
    CustomersView: () => import("./CustomersView"),
  },
  data() {
    return {
      loading: false,
      defaultPeriod: "today",
      period: "today",
      periods: ["yesterday", "today", "tomorrow", "thisweek", "nextweek"],
      service: "meal",
      view: "orders",
      views: {
        orders: "Orders View",
        customers: "Customers View",
      },
      servicePartnerName: "all",
      servicePartnerId: "all",
      provisionedOrders: [],
    };
  },
  computed: {
    gardenerId() {
      return this.$route.params.id;
    },
    title() {
      return this.loading ? "" : `${this.provisionedOrders.length} Orders`;
    },
  },
  created() {
    this.getProvisionedOrders();
  },

  methods: {
    setView(view) {
      this.view = view;
    },
    setService(service) {
      this.service = service;
      this.getProvisionedOrders();
    },
    setPeriod({ period }) {
      this.period = period;
      this.getProvisionedOrders();
    },
    getProvisionedOrders() {
      this.loading = true;
      const payload = {
        id: this.gardenerId,
        service: this.service,
        period: this.period,
      };
      gardener
        .orders(payload)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.provisionedOrders = formatGardenerOrders(data, this.service);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
