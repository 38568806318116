<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <template v-if="selectedCustomers.length">
          <el-button type="text" class="ml-10 text-grey-primary">
            {{ selectedCustomers.length }}
            {{ selectedCustomers.length == 1 ? "customer" : "customers" }}
            selected
          </el-button>
          <span class="line"> </span>
          <el-dropdown class="dropdown-with-buttons" @command="command">
            <el-button type="primary">
              Actions
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                command="send-bulk"
                icon="el-icon-circle-plus-outline"
                >Send Bulk Message
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <gardener-customers-filter
            :disabled="!setCustomers.length"
            :clear="filterParams.clear"
            @filter="filter"
          />
          <el-button
            type="plain"
            icon="eden-icon-upload"
            class="ml-10"
            :disabled="!setCustomers.length"
            @click="exporting = true"
          >
            Export
          </el-button>
        </template>
      </template>
    </eden-table-actions>
    <eden-filter-items
      v-if="filterParams.status"
      :params="filterParams.paramsLabel"
      @clear-filter="clearFilter"
      @clear-filters="clearFilters"
    />
    <template>
      <el-table
        :data="setCustomers"
        :key="sort.property"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="allowAccessFor(['superadmin', 'gardener', 'admin'])"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column width="250">
          <template slot="header">
            <eden-table-column-header
              :label="'Name'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <div class="is-flex align-center">
              <span
                :class="[
                  'indicator',
                  {
                    inactive: !scope.row.active_status,
                  },
                ]"
              ></span>
              <div>
                <router-link
                  class="text-primary font-sm"
                  :to="{
                    name: 'customers.individual',
                    params: { id: scope.row.id },
                  }"
                  >{{ formatName(scope.row.name) }}</router-link
                >
                <p class="text-grey-tertiary">
                  {{ scope.row.email }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <eden-table-column-header
              :label="'Phone Number'"
              :property="'phone_number'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'phone_number')"
            />
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ formatPhoneNumber(scope.row.phone_number) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template slot="header">
            <eden-table-column-header
              :label="'Status'"
              :property="'status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'status')"
            />
          </template>
          <template v-slot="scope">
            <span class="font-sm table--status">
              <el-tag :type="setType(scope.row.status)">
                {{ scope.row.status }}
              </el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <eden-table-column-header
              :label="'Services'"
              :property="'services'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'services')"
            />
          </template>
          <template v-slot="scope">
            <eden-services-list
              v-if="scope.row.services.length"
              :services="scope.row.services"
            />
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template slot="header">
            <eden-table-column-header
              :label="'Amount'"
              :property="'subscription_amount'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'subscription_amount')"
            />
          </template>
          <template slot-scope="scope">
            <span class="font-sm">{{
              formatPrice(scope.row.subscription_amount)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="170">
          <template slot="header">
            <eden-table-column-header
              :label="'Next Billing Date'"
              :property="'subscription_due'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'subscription_due')"
            />
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ formatDate(scope.row.subscription_due, "dddd do, m, y") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="60">
          <template v-slot="scope">
            <customer-actions
              :customer="scope.row"
              :section="'account'"
              @action="customerAction($event, scope.$index)"
              :allCustomers="setCustomers"
            />
          </template>
        </el-table-column>
      </el-table>
    </template>
    <gardener-customers-export :show.sync="exporting" :data="setCustomers" />
    <send-message-template
      :show.sync="sendBulk"
      :customers.sync="sentSelectedCustomers"
      :resent-message="message"
    />
  </div>
</template>

<script>
import CustomerActions from "@/components/Customers/Individual/Actions/CustomerActions";
import GardenerCustomersExport from "@/components/Gardeners/Export/GardenerCustomersExport";
import GardenerCustomersFilter from "@/components/Gardeners/Gardener/GardenerCustomersFilter";
import SendMessageTemplate from "@/components/Gardeners/Messaging/SendMessageTemplate";
import { formatGardenerCustomers } from "@/components/Gardeners/Gardener/gardener.config";

import gardeners from "@/requests/gardeners";

export default {
  name: "GardenerCustomers",
  components: {
    CustomerActions,
    GardenerCustomersExport,
    GardenerCustomersFilter,
    SendMessageTemplate,
  },
  props: {
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    gardenerId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchQuery: "",
      sort: {
        property: "",
        direction: "asc",
      },
      customer: {},
      exporting: false,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      filteredCustomers: [],
      selectedCustomers: [],
      sendBulk: false,
      showConfirm: false,
      sending: false,
      message: "",
      draftMessage: "",
      sentSelectedCustomers: [],
      loading: false,
    };
  },
  computed: {
    activeCustomers() {
      let list = [];
      if (this.filteredCustomers.length) {
        list = this.filteredCustomers.filter((customer) => customer.status);
      } else {
        list = this.customers.filter((customer) => customer.status);
      }
      return this.sortList(list, "name");
    },
    inactiveCustomers() {
      let list = [];
      if (this.filteredCustomers.length) {
        list = this.filteredCustomers.filter((customer) => !customer.status);
      } else {
        list = this.customers.filter((customer) => !customer.status);
      }
      return this.sortList(list, "name");
    },
    subscribers() {
      console.log(this.activeCustomers);
      const list = this.activeCustomers.filter(
        (customer) => customer.status === "subscriber",
      );
      return this.sortList(list, "name");
    },
    prospects() {
      const list = this.activeCustomers.filter(
        (customer) => customer.status === "prospect",
      );
      return this.sortList(list, "name");
    },
    paused() {
      const list = this.activeCustomers.filter(
        (customer) => customer.status === "paused",
      );
      return this.sortList(list, "name");
    },
    churned() {
      const list = this.activeCustomers.filter(
        (customer) => customer.status === "churned",
      );
      return this.sortList(list, "name");
    },
    combinedCustomers() {
      return this.subscribers.concat(
        this.paused,
        this.churned,
        this.prospects,
        this.inactiveCustomers,
      );
    },
    setCustomers() {
      if (this.searchQuery !== "") {
        const customers = this.customers.filter((customer) => {
          return (
            customer.name
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            customer.email
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          );
        });
        return customers;
      } else {
        // return this.sort.property
        //   ? this.sortArray({
        //       data: this.customers,
        //       property: this.sort.property,
        //       direction: this.sort.direction,
        //     })
        //   : this.customers;
        return this.combinedCustomers;
      }
    },
    title() {
      return `${this.setCustomers.length} Customers`;
    },
  },
  created() {
    console.log(this.$route);
    if (this.$route.params.message) {
      let paramInfo = this.$route.params.message;
      let customers = paramInfo.recipients.map((item) => {
        return item.receiver;
      });
      this.selectedCustomers = customers;
      this.command("send-bulk");
      this.message = paramInfo.message;
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedCustomers = val;
    },
    getCustomerServices(data) {
      if (data.length) {
        const services = Object.keys(data[0].plan);
        return this.sortList(services);
      } else {
        return [];
      }
    },
    search(query) {
      this.searchQuery = query;
    },
    command(command) {
      switch (command) {
        case "send-bulk":
          this.sendBulk = true;
          console.log(this.selectedCustomers);
          this.sentSelectedCustomers = this.selectedCustomers;
          break;
        default:
          //
          break;
      }
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
    },
    customerAction(event, index) {
      switch (event.action) {
        case "gardener-change":
          this.$emit("remove", this.setCustomers[index].id);
          this.setCustomers.splice(index, 1);
          break;
        case "active-status-change":
          this.setCustomers[index].active_status = event.data === "active";
          break;
        case "status-change":
          this.setCustomers[index].status = event.data;
          break;
        case "email-change":
          this.setCustomers[index].email = event.data;
          break;
        case "delete":
          this.$emit("remove", this.setCustomers[index].id);
          this.setCustomers.splice(index, 1);
          break;
        default:
          break;
      }
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      params["gardener_id"] = this.gardenerId;
      const stringedParams = this.sortObjectAsParams(params);
      gardeners
        .filterCustomers(stringedParams)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.filteredCustomers = formatGardenerCustomers(
            response.data.data.customers.data,
          );
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      (this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      }),
        (this.filteredCustomers = []);
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator {
  height: 7px;
  width: 7px;
  border-radius: 100px;
  margin-right: 16px;
  background: #03a84e;

  &.inactive {
    background: #e13135;
  }
}
.line {
  display: inline-block;
  margin: 0 10px;
  height: 25px;
  border-right: 1px solid var(--eden-grey-quinary);
}
</style>
