<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <gardener-schedule-filter
          class="mr-10"
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
        <orders-completion
          v-if="selectedOrders.length"
          :orders="selectedOrders"
          @completed="getGardenerSchedule({ period: period })"
        />
        <eden-periods :loading="loading" @set-period="getGardenerSchedule" />
      </template>
    </eden-table-actions>
    <template>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <el-table
          :data="pageData.data"
          @select-all="setSelectedOrders"
          @selection-change="setSelectedOrders"
        >
          <el-table-column type="selection" width="45"></el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <div class="table--header">
                <span>Order Id</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                class="text-primary"
                :to="{
                  name: 'orders.order',
                  params: { id: scope.row.order_id },
                }"
              >
                {{ scope.row.order_id }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'customers.individual',
                  params: { id: scope.row.user_id },
                }"
              >
                {{ formatName(scope.row.customer_name) }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <span class="eden-table-header"> Phone Number </span>
            </template>
            <template slot-scope="scope">
              <span>{{ formatPhoneNumber(scope.row.customer_phone_no) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot="header">
              <div class="table--header">
                <span>Status</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm table--status">
                <eden-order-status
                  :service="scope.row.service"
                  :activity="scope.row.last_marked_activity"
                  :completed-status="scope.row.completed_status"
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Service</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                <eden-services-list
                  :type="'single'"
                  :service="scope.row.service"
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column width="170">
            <template slot="header">
              <div class="table--header">
                <span>Pickup Date</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                v-if="scope.row.service.toLowerCase() === 'laundry'"
                class="font-sm"
                >{{
                  formatDate(scope.row.pickup_from_customer, "ddd do, m y")
                }}</span
              >
              <span v-else>Delivery only</span>
            </template>
          </el-table-column>
          <el-table-column width="170">
            <template slot="header">
              <div class="table--header">
                <span>Delivery Date</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">{{
                formatDate(scope.row.deliver_to_customer, "ddd do, m y")
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="pageData.from"
          :to="pageData.to"
          :total="pageData.total"
          :current-page.sync="page"
        />
      </template>
    </template>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import OrdersCompletion from "@/components/Orders/Actions/OrdersCompletion";
import GardenerScheduleFilter from "@/components/Gardeners/Gardener/Schedule/GardenerScheduleFilter";

export default {
  name: "GardenerSchedule",
  components: { OrdersCompletion, GardenerScheduleFilter },
  data() {
    return {
      loading: false,
      period: "today",
      periods: [
        "lastweek",
        "yesterday",
        "today",
        "tomorrow",
        "nexttwodays",
        "thisweek",
        "nextweek",
      ],
      page: 1,
      showPagination: true,
      selectedOrders: [],
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pageData: [],
      total: 0,
    };
  },
  computed: {
    gardenerId() {
      return parseInt(this.$route.params.id);
    },
    schedule() {
      return this.$store.getters.gardener_schedule;
    },
    data() {
      return this.schedule.pages[this.page].data;
    },
    // from() {
    //   return this.schedule.pages[this.page].from;
    // },
    // to() {
    //   return this.schedule.pages[this.page].to;
    // },
    // total() {
    //   return this.schedule.total;
    // },
    title() {
      return this.loading ? "-" : `${this.pageData.total} Orders`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.schedule.pages[this.page];
      if (!pageFetched) {
        this.getGardenerSchedule({ period: this.period });
        return;
      }
      this.pageData = this.schedule.pages[this.page];
    },
  },
  created() {
    this.getGardenerSchedule({ period: "today" });
  },
  methods: {
    getGardenerSchedule({ period }, filterParams = "") {
      this.loading = true;
      this.period = period;
      this.$store
        .dispatch(actions.GET_GARDENER_SCHEDULE, {
          id: this.gardenerId,
          period,
          page: this.page,
          filterParams,
        })
        .then(() => {
          this.pageData = this.schedule.pages[this.page];
          this.total = this.schedule.total;
          this.loading = false;
          console.log(this.schedule);
        });
    },
    setSelectedOrders(orders) {
      this.selectedOrders = orders;
    },
    filter({ params }) {
      const stringedParams = this.sortObjectAsParams(params);
      this.getGardenerSchedule({ period: this.period }, stringedParams);
    },
    clearFilter() {},
    clearFilters() {},
  },
};
</script>
