<template>
  <div>
    <eden-page-header
      :title="'Gardeners'"
      :subtitle="formatName(gardener.name)"
    />
    <eden-information-card>
      <template slot="content">
        <div class="gardener-profile">
          <div class="gardener-profile--summary">
            <eden-avatar :src="gardener.avatar" :size="70" />
            <p class="name">{{ formatName(gardener.name) }}</p>
            <span class="email">{{ gardener.email }}</span>
            <el-button type="plain" size="medium" @click="editProfile"
              >Edit Profile</el-button
            >
            <div>
              <div class="progress-description">
                <span>{{ ordersCompletedToday }}</span>
                of
                <span>{{ ordersToday }}</span> orders completed today
              </div>
              <el-progress
                :show-text="false"
                :percentage="percent(ordersCompletedToday, ordersToday)"
                :color="setPercentColor(ordersCompletedToday, ordersToday)"
                status="success"
              ></el-progress>
            </div>
          </div>
          <div class="gardener-profile--stats">
            <eden-page-stats
              :loading="loading"
              :stats="gardenerCustomerStats"
            />
            <gardener-sales :gardener-id="gardenerId" />
          </div>
        </div>
      </template>
    </eden-information-card>
    <payment-tracker-overview :view="'gardener'" />
    <el-tabs v-model="tab" @tab-click="updateRoute">
      <el-tab-pane label="Overview" name="overview">
        <gardener-overview :gardener="gardener" />
      </el-tab-pane>
      <el-tab-pane label="Customers" name="customers">
        <gardener-customers
          :gardener-id="Number(gardenerId)"
          :customers="gardener.customers"
          @remove="removeCustomer"
        />
      </el-tab-pane>
      <el-tab-pane label="Schedule" name="schedule">
        <gardener-schedule />
      </el-tab-pane>
      <el-tab-pane label="Orders" name="orders">
        <gardener-provisioned-orders />
      </el-tab-pane>
      <el-tab-pane label="Customer Feedback" name="feedback" disabled>
        <gardener-customer-feedback />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import GardenerSales from "@/components/Gardeners/Gardener/Overview/GardenerSales.vue";
import GardenerOverview from "@/components/Gardeners/Gardener/Overview/GardenerOverview.vue";
import GardenerCustomers from "@/components/Gardeners/Gardener/GardenerCustomers.vue";
import GardenerSchedule from "@/components/Gardeners/Gardener/Schedule/GardenerSchedule.vue";
import GardenerProvisionedOrders from "@/components/Gardeners/Gardener/ProvisionedOrders/GardenerProvisionedOrders.vue";
import GardenerCustomerFeedback from "@/components/Gardeners/Gardener/GardenerCustomerFeedback.vue";
import PaymentTrackerOverview from "@/components/Gardeners/PaymentTracker/PaymentTrackerOverview";

import { formatGardenerCustomers } from "@/components/Gardeners/Gardener/gardener.config";
import gardener from "@/requests/gardeners/gardener";
import * as mutations from "@/store/mutation-types";

export default {
  name: "Gardener",
  components: {
    GardenerOverview,
    GardenerCustomers,
    GardenerSchedule,
    GardenerProvisionedOrders,
    GardenerCustomerFeedback,
    GardenerSales,
    PaymentTrackerOverview,
  },
  data() {
    return {
      loading: false,
      tab: "overview",
      gardener: {},
    };
  },
  computed: {
    gardenerId() {
      return this.$route.params.id;
    },
    ordersCompletedToday() {
      return this.gardener.completed_orders_today || 0;
    },
    ordersToday() {
      return this.gardener.total_orders_today || 0;
    },
    gardenerCustomerStats() {
      const customers = this.gardener.customers || [];
      const subscribers = customers.filter(
        (customer) => customer.status === "subscriber"
      );
      const paused = customers.filter(
        (customer) => customer.status === "paused"
      );
      const churned = customers.filter(
        (customer) => customer.status === "churned"
      );

      return [
        {
          label: "Total Customers",
          figure: customers.length,
        },
        {
          label: "Subscribers",
          figure: subscribers.length,
        },
        {
          label: "Paused Customers",
          figure: paused.length,
        },
        {
          label: "Churned Customers",
          figure: churned.length,
        },
      ];
    },
  },
  created() {
    this.getGardener();
    let { tab } = this.$route.query;
    this.tab = tab ? tab : "overview";
  },
  methods: {
    getGardener() {
      this.loading = true;
      gardener
        .get(this.gardenerId)
        .then((response) => {
          this.gardener = {
            ...this.gardener,
            ...response.data.data.gardener,
            customers: formatGardenerCustomers(response.data.data.customers),
          };
          this.loading = false;
          console.log(this.gardener, "ga")
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateRoute(tab) {
      const currentTab = this.$route.query.tab;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "gardeners.gardener",
        params: { id: this.$route.params.id },
        query: { tab: tab.name },
      });
    },
    editProfile() {
      this.$router.push({ name: `gardeners.gardener-edit` });
    },
    removeCustomer(id) {
      this.gardener.customers = this.gardener.customers.filter(
        (customer) => customer.id !== id
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    next();
    setTimeout(() => {
      this.$store.commit(mutations.RESET_GARDENER_SCHEDULE);
    }, 500);
  },
};
</script>
<style lang="scss">
$profile-width: 374px;

.information-card {
  padding: 0;
  margin-bottom: 48px;
}

.gardener-profile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &--summary {
    padding: 16px 0;
    text-align: center;
    max-width: $profile-width;
    width: 100%;

    .name {
      font-weight: 500;
      font-size: 1.125rem;
    }

    .email {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--eden-grey-tertiary);
    }

    .el-button {
      margin: 15px auto;
    }

    .progress {
      &-description {
        margin-bottom: 8px;
        color: var(--eden-grey-tertiary);
        font-size: 0.825rem;

        span {
          font-weight: 600;
          color: var(--eden-grey-secondary);
        }
      }
    }
  }

  &--stats {
    max-width: 600px;
    width: 100%;

    .page-stat {
      margin: 0;
      padding: 15px;
      border: 0;

      &:first-child {
        border-bottom: 1px solid #f0f4f2;
      }

      &--head {
        margin-bottom: 10px;
      }

      &--card {
        margin-bottom: 0 !important;
      }

      &--body {
        margin: 0 !important;
      }

      .stat-figure {
        justify-content: center !important;
      }

      .stat-description {
        text-align: center !important;
      }
    }
  }

  @media (min-width: 1300px) {
    .gardener-profile--summary {
      border-right: 1px solid #f0f4f2;
    }

    .gardener-profile--stats {
      min-width: calc(100% - #{$profile-width});
    }
  }
}
</style>
