<template>
  <div>
    <el-button
      class="fadeIn mr-1"
      type="primary"
      plain
      @click="showConfirm = true"
      >Complete Orders</el-button
    >
    <eden-confirm-dialog
      title="Complete Orders"
      :button-type="'success'"
      :button-text="'Complete'"
      :button-status="completing"
      :show.sync="showConfirm"
      @confirm="completeOrders"
    >
      <p>Proceed to complete all selected orders</p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import orders from "@/requests/orders";

export default {
  name: "OrdersCompletion",
  props: {
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showConfirm: false,
      completing: false,
    };
  },
  methods: {
    completeOrders() {
      this.completing = true;
      const payload = {
        order_ids: this.orders.map((order) => order.order_id),
      };
      orders
        .complete(payload)
        .then((response) => {
          if (response.data.status) {
            this.showConfirm = false;
            this.$message.success(response.data.message);
            this.$emit("completed");
          }
          this.completing = false;
        })
        .catch((error) => {
          this.completing = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
