<template>
  <eden-information-card :title="'Gardener Information'">
    <template slot="content">
      <el-table :data="information" :show-header="false">
        <el-table-column>
          <template slot-scope="scope">
            <span>{{ scope.row.label }}</span>
            <p v-if="scope.row.text">
              {{ scope.row.text }}
            </p>
            <template v-else>
              <p v-for="(area, index) in scope.row.areas" :key="index">
                {{ area }}
              </p>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </eden-information-card>
</template>

<script>
export default {
  name: "GardenerOverview",
  props: {
    gardener: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    information() {
      return [
        {
          label: "Bio",
          text: this.gardener.sweet_bio || "-",
        },
        {
          label: "Phone number",
          text: this.formatPhoneNumber(this.gardener.phone_number) || "-",
        },
        {
          label: "Assigned Location Areas",
          areas: this.gardener.location_areas
            ? this.gardener.location_areas.map((area) => area.name)
            : [],
        },
        {
          label: "Slack ID",
          text: this.gardener.slack_id || "-",
        },
        // {
        //   label: "Sendbird ID",
        //   text: this.gardener.sendbird_id,
        // },
        {
          label: "Getstream ID",
          text: this.gardener.getstream_id,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  width: 100%;
  max-width: 320px;

  .el-table {
    color: #1d352a;

    span {
      color: var(--eden-grey-quaternary);
      font-weight: 400;
      font-size: 0.75rem;
    }

    p {
      margin: 4px 4px 4px 0;
      font-size: 0.825rem;
      line-height: 1.8;
      word-break: break-word;
    }
  }
}
</style>
